import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostLink from "../components/post-link"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const [query, setQuery] = useState("")
  useEffect(() => {
    setTimeout(() => {
      console.log("hi, effect")
    }, 2000)
  }, [])

  const queryMatch = RegExp(query, "i")

  return (
    <Layout>
      <SEO title="Sam &amp; Kristen's Receipes" />

      <p>
        <input
          value={query}
          onChange={ev => setQuery(ev.target.value)}
          type="text"
          placeholder="Filter recipes"
        />
      </p>

      <ul>
        {edges
          .filter(edge => queryMatch.test(edge.node.frontmatter.title))
          .map(edge => (
            <li key={edge.node.id}>
              <PostLink post={edge.node} />
            </li>
          ))}
      </ul>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
